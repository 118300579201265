import SideBar from "../components/sidebar";
import "../assets/css/layout.css";
import MobSideBar from "../components/mobSideBar";


function Layout(props) {
    return (
       <div className="main-container d-flex">
             <SideBar />
             
            <div className="content">
                <MobSideBar />
                {props.children}
            </div>
        </div>
    )
}
export default Layout;