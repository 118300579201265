import React, { useState, useEffect } from "react";
import Layout from "./layout";
import "../assets/css/alertes.css";
import "animate.css";
import Services from "../services/global.service";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [helpDeskStats, setHelpDeskStats] = useState({ total: 0, enCours: 0 });
  const [facturesStats, setFacturesStats] = useState({ total: 0, aPayer: 0 });
  const [documentsStats, setDocumentsStats] = useState({
    total: 0,
    enCours: 0,
  });
  const [approvisionnementsStats, setApprovisionnementsStats] = useState({
    total: 0,
    enCours: 0,
  });

  const fetchCounts = async () => {
    try {
      setLoading(true);
      const helpDeskResponse = await Services.fetchData("HelpDesk");
      const facturesResponse = await Services.fetchData("Factures");
      const documentsResponse = await Services.fetchData("Documents");
      const approvisionnementsResponse = await Services.fetchData(
        "Approvisionnements"
      );
      const helpDeskData = helpDeskResponse?.records || [];
      const facturesData = facturesResponse?.records || [];
      const documentsData = documentsResponse?.records || [];
      const approvisionnementsData = approvisionnementsResponse?.records || [];

      const helpDeskTotal = helpDeskData.length;
      const helpDeskEnCours = helpDeskData.filter(
        (item) => item.Statut !== "Fermé" && item.Statut !== "Résolu"
      ).length;

      const facturesTotal = facturesData.length;
      const facturesAPayer = facturesData.filter(
        (item) => item.Etat === "A payer"
      ).length;

      const documentsTotal = documentsData.filter(
        (item) => item.repertoire !== "Rapport Client"
      ).length;
      const documentsEnCours = documentsData.filter(
        (item) => item.repertoire === "Rapport Client"
      ).length;

      const approvisionnementsTotal = approvisionnementsData.length;
      const approvisionnementsEnCours = approvisionnementsData.filter(
        (item) => item.Statut !== "Annuler" && item.Statut !== "Termine"
      ).length;

      setHelpDeskStats({ total: helpDeskTotal, enCours: helpDeskEnCours });
      setFacturesStats({ total: facturesTotal, aPayer: facturesAPayer });
      setDocumentsStats({ total: documentsTotal, enCours: documentsEnCours });
      setApprovisionnementsStats({
        total: approvisionnementsTotal,
        enCours: approvisionnementsEnCours,
      });
      setLoading(false);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des nombres de modules:",
        error
      );
    }
  };

  const fetchMenu = () => {
    let menu = localStorage.getItem("__menu");
    if (menu == null || menu.length === 0) {
      fetch("/data/menu.json")
        .then((response) => response.json())
        .then((menuData) => {
          localStorage.setItem("__menu", JSON.stringify(menuData));
        })
        .catch((error) =>
          console.error(
            "Erreur lors de la récupération des permissions:",
            error
          )
        );
    }
  };

  useEffect(() => {
    fetchCounts();
    fetchMenu();
  }, []);

  const calculatePercentage = (partial, total) => {
    return total > 0 ? Math.round((partial / total) * 100) : 0;
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <div className="min-vh-100 bg-color container-al">
            <div className="">
              <div className="row d-md-block d-none">
                <div className="col-md-12 homeTitle">
                  <h1 className="animate__animated animate__zoomIn animate__delay-0.5s">
                    Dashboard
                  </h1>
                </div>
              </div>

              <div className="container mt-42 bg-primary-al">
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <div className="card l-bg-cherry animate__animated animate__fadeInDown animate__delay-0.55s">
                      <div  onClick={() => {
              window.location.href = `/requetes`;
            }} className="card-statistic-3 p-4">
                        <div className="card-icon card-icon-large">
                          <i className="fa fa-solid fa-headset" />
                        </div>
                        <div className="mb-4">
                          <h5 className="card-title mb-0">Requêtes</h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-7">
                            <h2
                              className="d-flex align-items-center mb-0"
                              title="Nombre de requetes"
                            >
                              {helpDeskStats.total}
                            </h2>
                          </div>
                          <div className="col-5 text-end">
                            <span title="En cours">
                              {helpDeskStats.enCours}{" "}
                              {loading ? (
                                <i className="fa fa-spinner"></i>
                              ) : (
                                "(en cours)"
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mt-1 "
                          data-height={8}
                          style={{ height: 8 }}
                        >
                          <div
                            className="progress-bar l-bg-cyan"
                            role="progressbar"
                            aria-valuenow={helpDeskStats.enCours}
                            aria-valuemin={0}
                            aria-valuemax={helpDeskStats.total}
                            style={{
                              width: `${calculatePercentage(
                                helpDeskStats.enCours,
                                helpDeskStats.total
                              )}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="card l-bg-blue-dark animate__animated animate__fadeInDown animate__delay-0.55s">
                      <div  onClick={() => {
              window.location.href = `/approvisionnements`;
            }} className="card-statistic-3 p-4">
                        <div className="card-icon card-icon-large">
                          <i className="fa fa-solid fa-wallet" />
                        </div>
                        <div className="mb-4">
                          <h5 className="card-title mb-0">
                            Approvisionnements
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-7">
                            <h2
                              className="d-flex align-items-center mb-0"
                              title="Nombre d'Approvisionnements"
                            >
                              {approvisionnementsStats.total}
                            </h2>
                          </div>
                          <div className="col-5 text-end">
                            <span title="En cours">
                              {" "}
                              {approvisionnementsStats.enCours}{" "}
                              {loading ? (
                                <i className="fa fa-spinner"></i>
                              ) : (
                                "(en cours)"
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mt-1 "
                          data-height={8}
                          style={{ height: 8 }}
                        >
                          <div
                            className="progress-bar l-bg-green"
                            role="progressbar"
                            aria-valuenow={approvisionnementsStats.enCours}
                            aria-valuemin={0}
                            aria-valuemax={approvisionnementsStats.total}
                            style={{
                              width: `${calculatePercentage(
                                approvisionnementsStats.enCours,
                                approvisionnementsStats.total
                              )}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="card l-bg-green-dark animate__animated animate__zoomIn animate__delay-0.55s">
                      <div  onClick={() => {
              window.location.href = `/documents`;
            }} className="card-statistic-3 p-4">
                        <div className="card-icon card-icon-large">
                          <i className="fa fa-solid fa-file-lines" />
                        </div>
                        <div className="mb-4">
                          <h5 className="card-title mb-0">Documents</h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-7">
                            <h2 className="d-flex align-items-center mb-0">
                              {documentsStats.total}{" "}
                              <span style={{ fontSize: "0.8rem" }}>
                                
                              </span>
                            </h2>
                          </div>
                          <div className="col-5 text-end">
                            <span>
                              {documentsStats.enCours}
                              {/* {calculatePercentage(
                            documentsStats.enCours,
                            documentsStats.total
                          )} */}
                              {loading ? (
                                <i className="fa fa-spinner"></i>
                              ) : (
                                <span style={{ fontSize: "0.8rem" }}>
                                  (Rapport client)
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mt-1 "
                          data-height={8}
                          style={{ height: 8 }}
                        >
                          <div
                            className="progress-bar l-bg-orange"
                            role="progressbar"
                            aria-valuenow={documentsStats.enCours}
                            aria-valuemin={0}
                            aria-valuemax={documentsStats.total}
                            style={{
                              width: `${calculatePercentage(
                                documentsStats.enCours,
                                documentsStats.total
                              )}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                    <div className="card l-bg-orange-dark animate__animated animate__zoomIn animate__delay-0.6s">
                      <div  onClick={() => {
              window.location.href = `/factures`;
            }} className="card-statistic-3 p-4">
                        <div className="card-icon card-icon-large">
                          <i className="fa fa-solid fa-receipt" />
                        </div>
                        <div className="mb-4">
                          <h5 className="card-title mb-0">Factures</h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-7">
                            <h2
                              className="d-flex align-items-center mb-0"
                              title="Nombre de Factures"
                            >
                              {facturesStats.total}
                            </h2>
                          </div>
                          <div className="col-5 text-end">
                            <span title="A payer">
                              {facturesStats.aPayer}{" "}
                              {loading ? (
                                <i className="fa fa-spinner"></i>
                              ) : (
                                "(A payer)"
                                // <i className="fa fa-credit-card" />
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mt-1 "
                          data-height={8}
                          style={{ height: 8 }}
                        >
                          <div
                            className="progress-bar l-bg-cyan"
                            role="progressbar"
                            aria-valuenow={facturesStats.aPayer}
                            aria-valuemin={0}
                            aria-valuemax={facturesStats.total}
                            style={{
                              width: `${calculatePercentage(
                                facturesStats.aPayer,
                                facturesStats.total
                              )}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
}
export default Dashboard;
