import React from "react";
import Modal from "react-bootstrap/Modal";
import "../assets/css/modal.css";
import "../assets/css/alertes.css";

const DataModal = ({ show, handleClose, message, isSuccess }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="text-center">
                    {isSuccess ? (
                        <>
                            <i className="fa fa-check-circle-o fa-2x" />
                            <p>{message || "Opération réussie !"}</p>
                        </>
                    ) : (
                        <>
                            <i className="fa fa-times fa-2x" />
                            <p>{message || "Opération échouée."}</p>
                        </>
                    )}
                </div>
                <div className="text-end">
                    <button onClick={handleClose} className="btn-secondary-al mt-3">Fermer</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DataModal;
