import React, { useEffect, useState } from "react";
import Layout from "./layout";
import "../assets/css/rapport.css";
import { useParams, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import "../assets/css/alertes.css";
import chevron from "../assets/icons/ep_arrow-up.svg";
import Services from "../services/global.service";
import Loading from "../components/loading";
import { Card } from "react-bootstrap";
import { fr } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';

const DetailComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [cta, setCta] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let menus = JSON.parse(localStorage.getItem("__menu"));
    const currentModule = menus.find(
      (item) => item.route === location.pathname
    );
    if (currentModule) {
      setPermissions(currentModule.permissions);
      setCta(currentModule.cta);
    } else {
      //   console.error("Module non trouvé dans menu.json");
    }
  }, [location.pathname, permissions]);

  const fetchData = async () => {
    const moduleName = Services.getModuleNameByPathname(location.pathname);
    const jsonData = await Services.fetchData(moduleName);
    if (jsonData && jsonData.records) {
      const item = jsonData.records.find(
        (record) => record.id.toString() === id
      );
      setData(item);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [id, location.pathname]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <Layout>
        <div className="min-vh-100 bg-color container-al rapport" id="rapport">
          <div className="container mt-42 bg-primary-al">
            Élément non trouvé
          </div>
        </div>
      </Layout>
    );
  }

  const headerTitle = Services.getHeaderTitle(location.pathname);

  const titleField = Services.detailTitle(location.pathname);
  const detailTitle = data[titleField];

  const currentModuleName = Services.getUpdateUrl(location.pathname);
  const updateUrl = Services.getUpdateUrl(location.pathname, id);
  const downloadUrl = `${data.file}`;

  const handleEdit = () => {
    navigate(updateUrl);
  };
  const handleRetour = () => {
    navigate(-1);
  };

  const divFields = () => {
    const detailViewFields = Services.getDetailViewFields(location.pathname);

    const picklistFields = [];
    const otherFields = [];
    let dateCreationField = null;
    let mobFieldView = [];
    let mobPicklistFields = [];

    const fieldsPerRow = 3;

    const getColorForPicklist = (field, fieldValue) => {
      const colorObject = field.colors.find(colorObj => Object.keys(colorObj)[0] === fieldValue);
      return colorObject ? Object.values(colorObject)[0] : "";
    };

    detailViewFields.forEach((field, index) => {
      const fieldValue = data[field.name]?.valeur || data[field.name] || "";

     
      if (field.type === "picklist") {
        const backgroundColor = getColorForPicklist(field, fieldValue);
        picklistFields.push(
          <span className="pr-3" key={index} style={{ backgroundColor, marginRight: '10px' }}>
            {fieldValue}
          </span>
        );
        mobPicklistFields.push(
          <div style={{ marginBottom: '9px' }} className="">
            <div className="col-sm-12">
              <label className="contentTitle" htmlFor={field.displayName}>
                {field.displayName}
              </label>
              <div className="mobDetail">
    
                {fieldValue}
              </div>
            </div>
          </div>
        )
      }
      else if (field.displayName === "Date de création" || field.displayName === "Created At") {
        if (fieldValue && !isNaN(new Date(fieldValue))) {
          const timeAgo = formatDistanceToNow(new Date(fieldValue), { addSuffix: true, locale: fr });

          dateCreationField = (
            <span className="mb-3" key={index}>
              {timeAgo},
            </span>
          );
        } else {
          dateCreationField = (
            <span className="mb-3" key={index}>

            </span>
          );
        }
      }
      else {
        otherFields.push(
          <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
            <label className="contentTitle" htmlFor={field.displayName}>
              {field.displayName}
            </label>
            <div className="mb-3 mobDetail" style={{ fontSize: "15px" }}>
              {field.displayName === "Montant" || field.displayName === "TTC" ? (
                `${parseFloat(data[field.name]).toFixed(0)} XOF`
              ) : typeof fieldValue === "string" ? (
                <span>{fieldValue.replace(/<\/?[^>]+(>|$)/g, "")}</span>
              ) : (
                <span>{fieldValue}</span>
              )}
            </div>
          </div>
        );
        mobFieldView.push(
          <div style={{ marginBottom: '9px' }} className="col-lg-4 col-md-6 hideToMobile p-2 col-sm-12" key={index}>
            <label className="contentTitle" htmlFor={field.displayName}>
              {field.displayName}
              </label>
            <div className="description" style={{ fontSize: "15px" }}>
              {field.displayName === "Montant" || field.displayName === "TTC" ? (
                `${parseFloat(data[field.name]).toFixed(0)} XOF`
              ) : typeof fieldValue === "string" ? (
                <span>{fieldValue.replace(/<\/?[^>]+(>|$)/g, "")}</span>
              ) : (
                <span>{fieldValue}</span>
              )}
            </div>
          </div>
        );
      }
    });



    const otherFieldsRows = [];
    for (let i = 0; i < otherFields.length; i += fieldsPerRow) {
      otherFieldsRows.push(
        <div className="row" key={i}>
          {otherFields.slice(i, i + fieldsPerRow)}
        </div>
      );
    }

    return {
      picklistFields,
      dateCreationField,
      otherFieldsRows,
      mobFieldView,
      mobPicklistFields
    };
  };

  const { mobPicklistFields, mobFieldView, picklistFields, dateCreationField, otherFieldsRows } = divFields();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al rapport" id="rapport">
        <div className="">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="bread-crumb">
                <span className="bread-item">Liste des {headerTitle}s </span>
                <img src={chevron} alt="chevron"></img>
                <span className="bread-item">{detailTitle}</span>
              </div>
              <div className="retour pt-26 mb-20">
                <a href="#" onClick={handleRetour} className="retour-link">
                  <span className="showToMobile"><i className="fa fa-close" aria-hidden="true"></i></span>
                  <span className="hideToMobile"><i className="fa fa-arrow-left" aria-hidden="true"></i>Retour</span>
                </a>
              </div>
            </div>

          </div>
          <div className="">
            <div className="">
              <div className="row">
                <div className="col-md-9 col-sm-12 hideToMobile mb-4">
                  <h2 className="detailTilte hideToMobile">{capitalizeFirstLetter(detailTitle)}</h2>
                   <div className="hideToMobile">
                   {dateCreationField} {picklistFields}
                    </div> 
      
                </div>
                <div className="col-md-3 hideToMobile">
                  {(permissions.update ||
                    location.pathname.includes("/documents")) &&
                    (location.pathname.includes("/documents") ? (
                      <a
                        href={downloadUrl}
                        className="btn-primary-al float-right"
                      >
                        Télécharger
                      </a>
                    ) : (
                      <a
                        href={downloadUrl}
                        className="btn-primary-al float-right"
                      >
                        Modifier
                      </a>
                    ))}
                </div>
              </div>
              <div className="showToMobile">
                <h2 className="detailMobTile">{capitalizeFirstLetter(detailTitle)}</h2>
              {mobPicklistFields}
              </div>
              {otherFieldsRows}
            </div>
          </div>
          {/* <div className="showToMobile">
            <Card style={{marginBottom: '10px'}} className="detailCard p-3 hideToMobile">
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <Card.Title><h2 className="detailTilte">{capitalizeFirstLetter(detailTitle)}</h2>
                    {dateCreationField}
                  </Card.Title>
                </div>
              </div>
            </Card>

            {mobPicklistFields}
            {mobFieldView}
          </div> */}


        </div>
      </div>
    </Layout>
  );
};

export default DetailComponent;
