import axios from "axios";
const API_URL = "https://ctci.estair.travel/estair_300/public/api/";
const setupApp = "ctci";
const token = localStorage.getItem("token");
console.log("api", API_URL);
const headersFiles = {
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
};
const login = async (email, password) => {
  return axios
    .post(API_URL + "user/login", {
      login: email,
      password: password,
      setup: setupApp,
    })
    .then((response) => {
      if (response) {
        console.log(response);
        localStorage.setItem("nom", response.data.data?.Nom);
        localStorage.setItem("prenom", response.data.data?.Prénom);
        localStorage.setItem("compte", response.data.data["Nom compte"]);
        localStorage.setItem("userid", response.data.data?.id);
        // localStorage.setItem("role", response.data.data?.role);
        // localStorage.setItem('profil', response.data.data?.profil);
        // localStorage.setItem('group', response.data.data?.group);
        // localStorage.setItem('token', response.data.token);
        // localStorage.setItem('id', response.data._id);
      }
      return response.data;
    })
    .catch((err) => {
      console.log("erreur login", err);
      throw err; // Throw the error to be caught in the promise chain
    });
};

const changePassword = (token, password) => {
  console.log(token);
  return axios
    .post(API_URL + "auth/change-password", {
      token: token,
      password: password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const resetPassword = (email) => {
  return axios
    .post(API_URL + "auth/reset-password", {
      email: email,
    })
    .then((response) => {
      if (response) {
        console.log(response.data);
      }
      return response.data;
    })
    .catch((err) => {
      console.log("erreur login", err);
      return err;
    });
};
const getCurrentUser = () => {
  const id = localStorage.getItem("id");
  if (id) return id;
};
const profil = () => {
  const userId = getCurrentUser();
  return axios
    .get(API_URL + "user/get/" + userId, headersFiles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });
};
const PasswordChange = async (data) => {
  const user = await profil();
  const datas = {
    email: user?.email,
    currentPassword: data?.actualPassword,
    newPassword: data?.newPassword,
  };
  console.log(datas);
  return axios
    .post(API_URL + "auth/password-change", datas)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const AuthService = {
  profil,
  login,
  resetPassword,
  changePassword,
  PasswordChange,
};

export default AuthService;
