import React, { useEffect, useState } from "react";
import "../assets/css/alertes.css";
import Datatables from "../components/datatable";
import Services from "../services/global.service";
import edit from "../assets/icons/cil_pen.svg";
import eye from "../assets/icons/solar_eye-outline.svg";
import trash from "../assets/icons/iconoir_trash.svg";
import Loading from "../components/loading";
import Layout from "./layout";
import { useLocation } from "react-router-dom";
import PaiementModal from "../components/paiement";
import ResultModal from "../components/resultMoodal";
import MobileView from "../components/MobileView";
import { displayName } from "react-quill";

const ListComponent = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentModuleName, setCurrentModuleName] = useState("");
  const [sortData, setSortData] = useState([]);
  const [tabs, setTabs] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [cta, setCta] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [result, setResult] = useState("");
  const [picklistInfo, setPicklistInfo] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resultParam = queryParams.get("result");

    if (resultParam === "success" || resultParam === "faild") {
      setResult(resultParam);
      setShowResultModal(true);
    }
  }, [location.search]);

  useEffect(() => {
    let menus = JSON.parse(localStorage.getItem("__menu"));
    const currentModule = menus.find(
      (item) => item.route === location.pathname
    );
    if (currentModule) {
      setPermissions(currentModule.permissions);
      setCta(currentModule.cta);
    } else {

    }
  }, [location.pathname]);

  const fetchData = async () => {
    setIsLoading(true);
    setSortData([]);
    setData([]);
    const currentModuleName = Services.getModuleNameByPathname(
      location.pathname
    );
    setCurrentModuleName(currentModuleName);
    let jsonMetaData = await Services.fetchMetadata(currentModuleName);
    if (jsonMetaData?.operations?.ListeView) {
      const fields = jsonMetaData.operations.ListeView.fields;
     
      let tempVar = [];

      const adjustedColumns = fields.map((column) => ({
        name: column.name,
        selector: (row) => {
          let fieldValue = row[column.name];
          if (column.type === "double" || column.type === "currency") {
            fieldValue = parseFloat(fieldValue).toFixed(0);
          }
          if (column.type === "picklist") {

            const colorObject = itemColor(column.colors, fieldValue);
            updatePicklistInfo(column, fieldValue, colorObject);

            
            return (
              <span
                style={{
                  backgroundColor: itemColor(column.colors, fieldValue),
                }}
              >
                {fieldValue}
              </span>
            );
          }
          if (
            column.type === "date" &&
            !["date de modification", "date de création"].includes(
              column.name.toLowerCase()
            )
          ) {
            fieldValue = new Date(fieldValue).toLocaleDateString();
          }

          return typeof fieldValue === "object" && fieldValue !== null
            ? fieldValue.valeur
            : fieldValue;
        },
        sortable: true,
      }));
      
      const updatePicklistInfo = (column, fieldValue, colorObject) => {
        setPicklistInfo((prevPicklistInfo) => [
          ...prevPicklistInfo,
          {
            displayName: column.displayName,
            value: fieldValue,
            color: colorObject,
          },
        ]);
      };
      

      const actionColumn = {
        name: "Actions",
        cell: (row) => (
          <div>
            {permissions.read && (
              <a className="cta" href={`${location.pathname}/detail/${row.id}`}>
                <img src={eye} alt="icon eye" />
              </a>
            )}

            {row.Statut !== "Résolu" && row.Statut !== "Fermé" && (
              <>
                {permissions.update && (
                  <a
                    className="cta"
                    href={`${location.pathname}/update/${row.id}`}
                  >
                    <img src={edit} alt="icon edit" className="w-75" />
                  </a>
                )}
                {permissions.delete && (
                  <a className="cta" onClick={() => handleDelete(row.id)}>
                    <img src={trash} alt="icon delete" className="w-75" />
                  </a>
                )}
              </>
            )}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      };
      
      setColumns([...adjustedColumns, actionColumn]);
      let jsonData = await Services.fetchData(currentModuleName);
      if (jsonData?.records?.length > 0) {
        groups(jsonData.records);
        const uniqueStates = [
          ...new Set(jsonData.records.map((item) => item.Statut)),
        ];
        const tabOptions = uniqueStates.map((state) => ({
          name: state,
          value: state,
        }));

        setTabs([{ name: "All", value: "all" }, ...tabOptions]);
        setData(jsonData.records);

      } else {
        setData([]);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [location.pathname, permissions]);

  const handleDelete = (id) => {
    // console.log(`Supprimer l'élément avec l'ID: ${id}`);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Date invalide";
    }
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat("fr-FR", options).format(date);
  };

  const getColorFromPicklist = (fieldName, value) => {
    const picklistItem = picklistInfo.find(
      (item) => item.displayName === fieldName && item.value === value
    );

    return picklistItem ? picklistItem.color : "";
  };
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const headerTitle = Services.getHeaderTitle(location.pathname);
  const createUrl = Services.getCreateUrl(location.pathname);

  const groups = (data) => {
    let key = "Created At";
    if (currentModuleName === "HelpDesk" || currentModuleName === "Documents") {
      key = "Date de création";
    }
    const groupByCategory = Map.groupBy(data, (item) => {
      return formatDate(new Date(item[key]));
    });
    let sortData = Array.from(groupByCategory).reverse();
    setSortData(sortData);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
 

   const filterMobileData = (items) => {
    if (Array.isArray(items) && items.length > 0) {
      return items.map((item) => {
        switch (headerTitle) {
          case "Requête":
            return {
              id: item["id"],
              title: item["Titre"] || "NA",
              priority: item["Priorité"] || "NA",
              priorityColor: getColorFromPicklist("Priorité", item["Priorité"]),
              status: item["Statut"] || "NA",
              statusColor: getColorFromPicklist("Statut", item["Statut"]),
              date: item["Created At"] || "NA",
              amount: item["Montant"],
            };
          case "Approvisionnement":
            return {
              id: item["id"],
              title: item["Nom Approvisionnement"] || "NA",
              type: item["Type"] || "NA",
              typeColor: getColorFromPicklist("Type", item["Type"]),
              status: item["Statut"] || "NA",
              statusColor: getColorFromPicklist("Statut", item["Statut"]),
              etat: item["Etat"] || "NA",
              etatColor: getColorFromPicklist("Etat", item["Etat"]),
              date: item["Created At"] || "NA",
              amount: item["Montant"],
            };
          case "Facture":
            return {
              id: item["id"],
              title: item["Number"] || "NA",
              pnr: item["Pnr"] || "NA",
              pncColor: getColorFromPicklist("Pnr", item["Pnr"]),
              etat: item["Etat"] || "NA",
              etatColor: getColorFromPicklist("Etat", item["Etat"]),
              amount: item["TTC"],
            };
          case "Document":
            return {
              id: item["id"],
              title: item["Titre"] || "NA",
              file: item["file"] || "",
              repertoire: item["repertoire"] || "NA",
            };
          default:
            return {};
        }
      });
    }
  };

  const itemColor = (colors, value) => {
    let itemColors = colors.filter((color) => {
      let keys = Object.keys(color);
      if (keys.length > 0) {
        return keys[0].indexOf(value) > -1;
      }
    });
    if (itemColors.length != 0) {
      let key = Object.keys(itemColors[0]);
      return itemColors[0][key[0]];
    }
  };


  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <div className="spinner"></div>
        </div>
      ) : (
        <Layout>
          <div className="min-vh-100 bg-color container-al">
            <div className="">
              <PaiementModal isOpen={isModalOpen} updateIsOpen={toggleModal} />
              <ResultModal
                show={showResultModal}
                handleClose={() => setShowResultModal(false)}
                result={result}
              />
              <div className="d-md-block d-none w-100">
                <div className="row">
                  <div className="col-lg-6">
                    <h2>Liste des {headerTitle}s</h2>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center justify-content-end">
                    {permissions.create &&
                      (cta.type === "pop-up" ? (
                        <button
                          id={cta.type}
                          className="btn-secondary-al mx-2"
                          onClick={toggleModal}
                        >
                          {cta.label || `Créer un ${headerTitle.toLowerCase()}`}
                        </button>
                      ) : (
                        <a
                          id={cta.type}
                          className="btn-secondary-al mx-2"
                          href={createUrl}
                        >
                          {cta.label || `Créer un ${headerTitle.toLowerCase()}`}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
              <div className="mt-42 bg-primary-al d-md-block d-none">
                {isLoading ? (
                  <Loading />
                ) : (
                  <Datatables
                    data={data}
                    columns={columns}
                    path={`${location.pathname}/detail`}
                    tabs={tabs}
                  />
                )}
              </div>
              <div className="mt-42 bg-primary-al d-md-none d-block animate__animated animate__zoomIn animate__delay-1s">
                {sortData.map((t, i) => (
                  <div>
                    <p
                      className=""
                      style={{
                        color: "#6e7071",
                        marginBottom: "3px",
                        marginLeft: "4px",
                        fontSize:"14px",
                      }}
                      key={i}
                    >
                      {capitalizeFirstLetter(t[0])}
                    </p>
                    {filterMobileData(t[1]).map((item, index) => (
                      <MobileView
                        key={index}
                        module={headerTitle}
                        path={`${location.pathname}/detail`}
                        data={{
                          title: item.title,
                          priority: item.priority,
                          status: item.status,
                          amount: item.amount,
                          pnr: item.pnr,
                          type: item.type,
                          etat: item.etat,
                          id: item.id,
                          file: item.file,
                          repertoire: item.repertoire,
                          date: item.date,
                          statusColor: item.statusColor,
                          etatColor: item.etatColor,
                          priorityColor: item.priorityColor,
                          pncColor: item.pncColor,
                          typeColor: item.typeColor,
                        }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default ListComponent;
