import React from "react";
import { Card } from "react-bootstrap";
import "../assets/css/alertes.css";


const MobileView = ({ module, data, path }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const truncateText = (text, maxLength = 10) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };
  
  const renderContent = () => {
    switch (module) {
      case "Requête":
        return (
          <Card
            onClick={() => {
              window.location.href = `${path}/${data.id}`;
            }}
            className="mb-3 factDocBg animate__animated animate__zoomIn animate__delay-0.5s"
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <p style={{marginBottom:"3px"}}>
                 
                  <span style={{color:'#2a479b'}}> {capitalizeFirstLetter(data["title"])}</span>
                </p>
              </div>
              <div
                className="detailCardInfon"
                style={{ fontSize: "14px" }}
              >
                <div>
                  <span style={{color: data.priorityColor}}>
                    {truncateText(data["priority"])}
                  </span>
                  
                </div>
                <div>
                  <span style={{color: data.statusColor }}>
                    {truncateText(data.status)}
                  </span>
                 
                </div>
                {data.amount && (
                  <div>
                    
                    {truncateText(parseFloat(data.amount).toLocaleString())} XOF
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        );

      case "Approvisionnement":
        return (
          <Card
            onClick={() => {
              window.location.href = `${path}/${data.id}`;
            }}
            className="mb-3 factDocBg animate__animated animate__zoomIn animate__delay-1s"
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <p style={{marginBottom:"3px;"}}>
                 
                  <span style={{color:'#2a479b'}}> {capitalizeFirstLetter(data["title"])}</span>
                </p>
              </div>
              <div className="detailCardInfon">
                <div style={{ fontSize: "14px" }}>
                
                  <span style={{ color: data.typeColor}}>{truncateText(data["type"])}</span>
                </div>
                <div>
                  <span style={{color: data.statusColor}}>{data.status}</span>
                  
                </div>
                {data.amount && (
                  <div>

                    <span>
                     
                      {truncateText(parseFloat(data.amount).toLocaleString())} XOF
                    </span>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        );

      case "Facture":
        return (
          <Card
            onClick={() => {
              window.location.href = `${path}/${data.id}`;
            }}
            className="mb-3 factDocBg animate__animated animate__zoomIn animate__delay-1s"
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <p style={{marginBottom:"3px;"}}>
                 
                  <span style={{color:'#294991'}}>{data["title"]}</span>
                </p>
              </div>
              <div className="detailCardInfon">
                
                <div>
                  <span
                    style={{
                
                      color: data.pnrColor,
                    }}
                  >{truncateText(data.pnr)}</span>
                  
                </div>
                <div style={{ fontSize: "14px" }}>
                  <span
                    style={{
                     
                      color: data.etatColor,
                    }}
                  > {truncateText(data.etat)}</span>
                 
                </div>
                {data.amount && (
                  <div>
                    <span>{truncateText(parseFloat(data.amount).toLocaleString())} XOF</span>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        );

      case "Document":
        return (
          <Card
            onClick={() => {
              if (data.file.length !== 0) {
                window.location.href = `${path}/${data.id}`;
              }
            }}
            className="mb-3 factDocBg animate__animated animate__zoomIn animate__delay-1s"
          >
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center" style={{fontSize:'12px', color:'#333'}}>
                <p style={{marginBottom:"3px;"}}>
                 
                  <span style={{color:'#294991'}}>  {capitalizeFirstLetter(data["title"])}</span>
                </p>
                <a href={`${path}/$ {capitalizeFirstLetter(data["title"])}`}>
                 
                </a>
              </div>
              <div className="detailCardInfon">
                {/* <div>
                  <strong>File:</strong>{" "}
                  <span
                    style={{
                      color: "#16a600 !important",
                      color: "#16a600  !important",
                    }}
                  ></span>
                   {capitalizeFirstLetter(data["title"])}
                </div> */}
                <div style={{ fontSize: "14px" }}>
                  {data.repertoire}
                </div>
                {data.amount && (
                  <div>
                   
                    {parseFloat(data.amount).toLocaleString()} XOF
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        );

      default:
        return null;
    }
  };

  return <div className="mobile-view">{renderContent()}</div>;
};

export default MobileView;
