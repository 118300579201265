import React, { useState, useEffect } from "react";
import Layout from "./layout";
import "../assets/css/alertes.css";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Services from "../services/global.service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import chevron from "../assets/icons/ep_arrow-up.svg";
import DataModal from "../components/DataModal";

const CreateComponent = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showDataModal, setShowDataModal] = useState(false);

  useEffect(() => {
    const loadFields = async () => {
      const fieldsData = Services.getCreateViewFields(location.pathname);
      if (fieldsData.length === 0) {
        const currentModuleName = Services.getModuleNameByPathname(
          location.pathname
        );
        const jsonMetaData = await Services.getMetadata(currentModuleName);
        if (jsonMetaData) {
          Services.storeDataInLocalStorage(
            `Meta_${currentModuleName}`,
            jsonMetaData
          );
          const loadedFields =
            jsonMetaData?.operations?.CreateView?.fields || [];
          setFields(loadedFields);
        } else {
          console.error("Failed to fetch metadata.");
        }
      } else {
        setFields(fieldsData);
      }
    };

    loadFields();
  }, [location.pathname]);

  useEffect(() => {
    const initialData = fields.reduce((acc, field) => {
      acc[field.displayName] = "";
      return acc;
    }, {});
    setData(initialData);
  }, [fields]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });

    if (Services.isFieldRequired(location.pathname, name) && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/_/g, " ")} Obligatoire`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    setData({
      ...data,
      [field]: selectedOption ? selectedOption.value : "",
    });

    if (Services.isFieldRequired(location.pathname, field) && !selectedOption) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${field.replace(/_/g, " ")} Obligatoire`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleDateChange = (date, field) => {
    setData({
      ...data,
      [field]: date,
    });

    if (Services.isFieldRequired(location.pathname, field) && !date) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${field.replace(/_/g, " ")} Obligatoire`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleQuillChange = (value, field) => {
    setData({
      ...data,
      [field]: value,
    });

    if (Services.isFieldRequired(location.pathname, field) && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${field.replace(/_/g, " ")} Obligatoire`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const sortedFields = fields.slice().sort((a, b) => {
    if (a.type === "text" && b.type !== "text") return 1;
    if (a.type !== "text" && b.type === "text") return -1;
    return 0;
  });

  const renderField = (field) => {
    const key = field.name;
    const fieldType = field.type;
    const fieldOptions = field.options || [];
    const required = field.mandatory;
    const placeholder = field.placeholder || "";
    const value = data[key];
    const columnClass =
      fieldType === "textarea" ? "col-12" : "col-md-6 col-sm-12";

    switch (fieldType) {
      case "string":
        return (
          <div className={columnClass} key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")}
              {field.mandatory ? "(*)" : ""}
            </label>
            <input
              type="text"
              id={key}
              name={key}
              className="form-control"
              value={value || ""}
              required={required}
              onChange={handleInputChange}
              placeholder={placeholder}
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      case "currency":
        return (
          <div className={columnClass} key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")} {field.mandatory ? "(*)" : ""}
            </label>
            <input
              type="number"
              id={key}
              name={key}
              className="form-control"
              value={value || ""}
              required={required}
              onChange={handleInputChange}
              placeholder={placeholder}
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      case "double":
        return (
          <div className={columnClass} key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")}
              {field.mandatory ? "(*)" : ""}
            </label>
            <input
              type="number"
              id={key}
              name={key}
              className="form-control"
              value={value || ""}
              onChange={handleInputChange}
              placeholder={placeholder}
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      case "picklist":
        return (
          <div className={columnClass} key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")}
              {field.mandatory ? "(*)" : ""}
            </label>
            <Select
              id={key}
              name={key}
              value={
                fieldOptions.find((option) => option === value)
                  ? { value, label: value }
                  : null
              }
              options={fieldOptions.map((option) => ({
                value: option,
                label: option,
              }))}
              placeholder="Sélectionner..."
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, key)
              }
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      case "date":
        return (
          <div className={columnClass} key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")}
              {field.mandatory ? "(*)" : ""}
            </label>
            <DatePicker
              id={key}
              name={key}
              className="form-control w-100"
              selected={value ? new Date(value) : null}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleDateChange(date, key)}
              placeholderText={placeholder}
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      case "text":
        return (
          <div className="col-12" key={key}>
            <label htmlFor={key}>
              {field.name.replace(/_/g, " ")}
              {field.mandatory ? "(*)" : ""}
            </label>
            <ReactQuill
              id={key}
              name={key}
              value={value || ""}
              onChange={(value) => handleQuillChange(value, key)}
              placeholder={placeholder}
            />
            {required && errors[key] && (
              <div className="text-danger">{errors[key]}</div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const formContents = () => {
    const rows = [];

    for (let i = 0; i < sortedFields.length; i += 2) {
      const firstField = sortedFields[i];
      const secondField = sortedFields[i + 1];

      rows.push(
        <div className="row mt-20" key={i}>
          {renderField(firstField)}
          {secondField ? renderField(secondField) : null}
        </div>
      );
    }

    return rows;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const currentModuleName = Services.getModuleNameByPathname(
      location.pathname
    );
    console.log(data);
    const payload = {
      setup: Services.SETUP,
      moduleName: currentModuleName,
      object: data,
      compte: localStorage.getItem("compte"),
    };
    console.log("data", payload);
    try {
      const response = await fetch(Services.API_URL + "user/module/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("response", response);
      console.log("result", result);

      if (response.ok && result.success) {
        let res = result.data;
        let dataInStorage = localStorage.getItem(currentModuleName + "_data");
        let parseDataInStorage = JSON.parse(dataInStorage);
        let records = parseDataInStorage?.records;
        let newDataInStorage = res.concat(records);
        parseDataInStorage.records = newDataInStorage;
        localStorage.setItem(
          currentModuleName + "_data",
          JSON.stringify(parseDataInStorage)
        );
        console.log("Enregistrement réussi:", result);
        setModalMessage("Votre opération a été effectuée avec succès !");
        setIsSuccess(true);
        setShowDataModal(true); // Affiche le modal
        setTimeout(() => {
          navigate("/requetes"); // Redirige après succès
        }, 2000);
      } else {
        const errorText =
          result.message || "Une erreur est survenue lors de l'enregistrement.";
        setModalMessage(`Erreur: ${errorText}`);
        setIsSuccess(false);
        setShowDataModal(true); // Affiche le modal en cas d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement:", error);
      setModalMessage(`Erreur: ${error.message}`);
      setIsSuccess(false);
      setShowDataModal(true); // Affiche le modal en cas d'erreur
    } finally {
      setIsLoading(false);
    }
  };

  const headerTitle = Services.getHeaderTitle(location.pathname);
  const handleRetour = () => {
    navigate(-1);
  };
  const handleCloseModal = () => setShowDataModal(false);

  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al">
        <div className="">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="bread-crumb">
              <span className="bread-item">Liste {headerTitle}</span>
              <img src={chevron} alt="chevron"></img>
              <span className="bread-item">Créer {headerTitle}</span>
            </div>
            <div className=" pt-26 mb-20">
              <a
                href="javascript:;"
                onClick={handleRetour}
                className="retour-link"
              >
                <span className="showToMobile"><i className="fa fa-close" aria-hidden="true"></i></span>
                <span className="hideToMobile"><i className="fa fa-arrow-left" aria-hidden="true"></i>Retour</span>
              </a>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-md-12">
              <h1>Créer {headerTitle}</h1>
            </div>
            <hr></hr>
          </div>
        </div>
        <div className="container-al bg-primary-al">
          <form onSubmit={handleFormSubmit}>
            {formContents()}
            <div className="mt-20">
              <button
                type="submit"
                disabled={isLoading}
                className="btn-primary-al float-right mt-20"
              >
                {" "}
                {isLoading ? "Chargement..." : "Créer"}
              </button>
              <a
                href="javascript:;"
                onClick={handleRetour}
                className="cta mt-20"
              >
                Annuler
              </a>
            </div>
          </form>
        </div>
      </div>
      <DataModal
        show={showDataModal}
        handleClose={handleCloseModal}
        isSuccess={isSuccess}
        message={modalMessage}
      />
    </Layout>
  );
};

export default CreateComponent;
