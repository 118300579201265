import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import "../assets/css/modal.css";
import "../assets/css/alertes.css";

const ResultModal = ({ show, handleClose, result }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="text-center">
                    {result === "success" ? (
                        <>
                            <i className="fa fa-check-circle-o fa-4x" />
                            <p>Votre opération a été prise en compte et est en cours de traitement.</p>
                        </>
                    ) : (
                        <>
                              <i className="fa fa-times" />
                            <p>Votre opération a échoué</p>
                        </>
                    )}
                </div>
                <div className="text-end">
                     <button onClick={handleClose} className="btn-secondary-al mt-3">Fermer</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ResultModal;