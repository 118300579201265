import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../assets/css/modal.css'
// import Services from "../services/global.service";

import Eye from '../assets/icons/solar_eye-outline.svg';
import { useEffect, useState } from "react";

const ModalResetPassword = ({ isOpen, updateIsOpen}) => {
    const initialValues = {
        email: "",
      };  
     
  const validationSchema = Yup.object({
   
    email: Yup.string()
      .required("Champs requis ")
  });
  const onSubmit = (values) => {
    // AuthService.PasswordChange(values)
    // .then((res)=>{
    //   if(res?.response?.status===401){
    //     toast.error(res?.response?.data?.message);
    //   }
    //    else{
    //      toast.success("Votre mot de passe à été changé avec succès ");
    //    }
    //     console.log(res)
    // })
    // .catch((err)=>{
    //     toast.error('error')
    // })
    
  };
  const hideModal=()=>{
    updateIsOpen()
  }
 
  useEffect(()=>{

   },[]);
  return (
    <Modal show={isOpen} onHide={updateIsOpen} className="modal-up">
      <Modal.Header>
        <Modal.Title>
        
           <h3>Mot de passe oublié</h3> 
        
      
     </Modal.Title>  
   <button className="btn btn-link" onClick={()=>updateIsOpen()}> </button> 
      </Modal.Header>
      <Modal.Body>



    <Formik initialValues={initialValues} onSubmit={onSubmit}  validationSchema={validationSchema}>
    <Form className=" m-auto">
      <div className=" mt-2">
        <div className="password-field">
          <label htmlFor="pseudo">E-mail</label>
        <div className="password-input-container"> 
        <Field
            name="email"
            className="form-control"
             type="email"
            placeholder="Renseignez votre e-mail"
           
          />
          
         
        </div>  <ErrorMessage name="email" component="div" className="text-danger" />
        </div> 
      </div>

      <button type="submit" className="btn-primary-al mt-3 float-right">
       Envoyer
      </button>
    </Form>
  </Formik>
     
      </Modal.Body>
    </Modal>
  );
}

export default ModalResetPassword;
