import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import '../App.css';

export default function Datatables({ data, columns, path, tabs }) {
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs.length > 0 ? tabs[0].value : 'all');

  const customStyles = {
    table: {
      style: {
        backgroundColor: '#FCFCFC',
        fontSize: "16px",
        borderRadius: "10px",
      },
    },
    headCells: {
      style: {
        backgroundColor: '#F4F3F3',
        fontSize: "16px",
        fontFamily: 'DM-Sans-Medium',
        fontWeight: '600',
      }
    },
    rows: {
      style: {
        cursor: 'pointer',
        backgroundColor: "#fff",
        borderBottomStyle: "#DEDEDE",
        borderBottomWidth: "90%",
        fontSize: "16px",
        fontFamily: 'DM-Sans-Regular',
        minHeight: '64px'
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',
  };

  const handleRowClick = (row) => {

    window.location.href = `${path}/${row.id}`;
  };

  const filterData = () => {
    let tempFilteredData = data;

    if (!Array.isArray(tempFilteredData)) {
      // Handle the case when data is not an array (or undefined)
      tempFilteredData = [];
    }

    if (currentTab !== 'all') {
      tempFilteredData = tempFilteredData?.filter(item => item?.Statut === currentTab);
    }

    if (filterText) {
      tempFilteredData = tempFilteredData?.filter(
        item =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );
    }

    setFilteredItems(tempFilteredData);
  };

  useEffect(() => {
    filterData();
  }, [currentTab, data, filterText]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <>
        <div className='col-12 '>
          <input
            className='form-control'
            id="search"
            type="text"
            placeholder="Recherche"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="col-md-12 tabs-container mt-38">
          {tabs?.map(el => (
            <div key={el.value} className={`tab ${currentTab === el.value ? 'active' : ''}`} onClick={() => setCurrentTab(el?.value)}>
              <a>{el?.name}</a>
            </div>
          ))}
        </div>
      </>
    );
  }, [filterText, currentTab, tabs]);

  const handleSelectedRowsChange = (selectedRows) => {
    const selectedIndexes = selectedRows.selectedRows.map((row) => row._id);
    setSelectedRows(selectedIndexes);
  };

  return (
    <>
      <div className='col-md-12 mt-24'>
        {data && <DataTable
          columns={columns}
          data={filteredItems}
          className="datatable-all mt-24"
          pagination
          paginationComponentOptions={paginationComponentOptions}
          customStyles={customStyles}
          onRowClicked={(row) => {
            if (path !== '') {
              handleRowClick(row);
            }
          }}
          subHeader
          subHeaderComponent={subHeaderComponent}
          onSelectedRowsChange={handleSelectedRowsChange}
        />}
      </div>
    </>
  );
}
