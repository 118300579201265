import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import '../assets/css/modal.css';
import "../assets/css/alertes.css";
import logoWave from "../assets/icons/logowave.jpg";

const ModalWave = ({ isOpen, updateIsOpen }) => {
    const [montant, setMontant] = useState('');
    const [error, setError] = useState('');

    const validateMontant = (value) => {
        const regex = /^[0-9]+$/;
        return regex.test(value);
    };
    const hideModal = () => {
        updateIsOpen()
      }
    const handleChange = (e) => {
        const value = e.target.value;
        if (validateMontant(value)) {
            setMontant(value);
            setError('');
        } else {
            setError('Entrer un montant valide');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (montant === '') {
            setError('Veuillez entrer le montant.');
        } else {
            try {
                const response = await fetch('https://ctci.estair.travel/estair_300/public/api/wave/request', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ montant }),
                });

                const result = await response.json();

                if (result.success) {
                    window.location.href = result.data;
                } else {
                    setError(result.message || 'Une erreur est survenue lors de la transaction.');
                }
            } catch (error) {
                setError('Une erreur est survenue lors de la requête.');
                console.error('Erreur:', error);
            }
        }
    };

    return (
        <Modal show={isOpen} onHide={updateIsOpen} className="modal-up">
            <Modal.Body>
                <div className="row py-4 my-4">
                    <div className="col-4">
                        <img style={{ width: 60, borderRadius: 15 }} src={logoWave} alt="logo Wave" />
                    </div>
                    <div className="col-8">
                        <h4>
                            Rechargement Wave
                        </h4>
                    </div>
                    <div className="Form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-3 col-sm-12"></div>
                                <div className="col-md-9 col-sm-12">
                                    <div className="mb-3 mt-4">
                                        <label htmlFor="montant">Montant de la transaction</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="montant" 
                                            value={montant}
                                            onChange={handleChange}
                                            required
                                        />
                                        {error && <div className="text-danger mt-1">{error}</div>}
                                    </div>
                                    <div className="text-end">
                                        <a onClick={() => { hideModal() }} className="cta mr-4 mt-3">
                                            Annuler
                                        </a>
                                        &nbsp; &nbsp; &nbsp;
                                        <button type="submit" className="btn-secondary-al">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalWave;