import { Route, Routes, BrowserRouter, redirect } from "react-router-dom";
import './App.css';
import './assets/css/btn.css';
import './assets/css/ui.css';
import Dashboard from "./views/dashboard";
import Login from "./views/login";
import ProtectedRoute from './utils/protectedRoutes'
import ListComponent from "./views/list";
import DetailComponent from "./views/detail";
import UpdateComponent from "./views/update";
import CreateComponent from "./views/create";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>


          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          * <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/factures/create" element={<CreateComponent />} />
                  <Route path="/requetes/create" element={<CreateComponent />} />
                  <Route path="/approvisionnements/create" element={<CreateComponent />} />
                  <Route path="/factures" element={<ListComponent />} />
                  <Route path="/documents" element={<ListComponent />} />
                  <Route path="/documents/create" element={<CreateComponent />} />
                  <Route path="/documents/detail/:id" element={<DetailComponent />} />
                  <Route path="/documents/update/:id" element={<UpdateComponent />} />
                  <Route path="/requetes" element={<ListComponent />} />
                  <Route path="/approvisionnements" element={<ListComponent />} />
                  <Route path="/requetes/detail/:id" element={<DetailComponent />} />
                  <Route path="/factures/detail/:id" element={<DetailComponent />} />
                  <Route path="/approvisionnements/detail/:id" element={<DetailComponent />} />
                  <Route path="/requetes/update/:id" element={<UpdateComponent />} />
                  <Route path="/factures/update/:id" element={<UpdateComponent />} />
                  <Route path="/approvisionnements/update/:id" element={<UpdateComponent />} />

                </Routes>
              </ProtectedRoute>
            }

          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
