import Modal from "react-bootstrap/Modal";
import '../assets/css/modal.css'
import { useEffect, useState } from "react";
import logoCeteris from "../assets/icons/Ceteris.jpg";
import logoWave from "../assets/icons/logowave.jpg";
import logoOm from "../assets/icons/logoOm.png";
import ModalWave from "./modalWave";

const PaiementModal = ({ isOpen, updateIsOpen }) => {

    const [modalWave, setModalWave] = useState(false);
    const toggleModalWave = () => {
        setModalWave(!modalWave);
    }

    const hideModal = () => {
        updateIsOpen()
    }

    useEffect(() => {

    }, []);
    return (
        <Modal show={isOpen} onHide={updateIsOpen} className="modal-up py-3">
            <Modal.Body>

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <img style={{ width: 96 }} src={logoCeteris} alt="Logo Ceteris" />
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <h4>Rechargement de Comptes</h4>
                            <div className="pt-4 my-4 d-flex justify-content-between align-items-center">
                                <div>
                                <a href="#" onClick={toggleModalWave}>
                                            <img style={{ width: 80, borderRadius: 15 }} src={logoWave} alt="logo Wave" />
                                </a>
                                </div>
                                <div>
                                    <h3>OU</h3>
                                </div>
                                <div>
                                    <a href="tel:#144*4*3*2609#" target="_blank" rel="noopener noreferrer">
                                        <img style={{ width: 80 }} src={logoOm} alt="logo Orange Money" />
                                    </a>
                                </div>
                            </div>
                            <div className="text-end">
                                <button onClick={() => { updateIsOpen() }} className="cta mt-3 ">
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalWave isOpen={modalWave} updateIsOpen={toggleModalWave} />
            </Modal.Body>
        </Modal>
    );
}

export default PaiementModal;
