import React from "react";
import { useLocation } from 'react-router-dom';

const EditButton = ({ permissions, downloadUrl, updateUrl }) => {
    const location = useLocation();
  
    if (!permissions || (!permissions.update && !location.pathname.includes("/documents"))) {
        return null;
    }

    return (
        location.pathname.includes("/documents") ? (
            <a style={{marginRight: '14px'}} href={downloadUrl} className="btn-secondary-icon">
                <i className="fa fa-download" aria-hidden="true"></i>
            </a>
        ) : (
            <a style={{marginRight: '14px'}} href={updateUrl} className="btn-secondary-icon">
                <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
        )
    );
};

export default EditButton;
