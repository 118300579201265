import React, { useEffect, useState } from "react";
import axios from 'axios';
import Layout from "./layout";
import '../assets/css/rapport.css';
import { useParams, useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import Services from "../services/global.service";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import chevron from '../assets/icons/ep_arrow-up.svg';
import Loading from "../components/loading";

const UpdateComponent = () => {
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {
        const fetchData = async () => {
            const moduleName = Services.getModuleNameByPathname(location.pathname);
            let jsonData = Services.getDataFromLocalStorage(`${moduleName}_data`);

            if (jsonData && jsonData.records) {
                const item = jsonData.records.find(record => record.id.toString() === id);
                setData(item);
            } else {
                console.error('Données non trouvées dans le localStorage');
            }

            setIsLoading(false);
        };

        fetchData();
    }, [id, location.pathname]);

    if (isLoading) {
        return <Loading />;
    }

    if (!data || Object.keys(data).length === 0) {
        return <div>Élément non trouvé</div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });

        if (Services.isFieldRequired(location.pathname, name) && !value) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `${name.replace(/_/g, ' ')} Obligatoire`
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    const handleSelectChange = (selectedOption, field) => {
        setData({
            ...data,
            [field]: selectedOption ? selectedOption.value : ''
        });

        if (Services.isFieldRequired(location.pathname, field) && !selectedOption) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: `${field.replace(/_/g, ' ')} Obligatoire`
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[field];
                return newErrors;
            });
        }
    };

    const handleDateChange = (date, field) => {
        setData({
            ...data,
            [field]: date
        });

        if (Services.isFieldRequired(location.pathname, field) && !date) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: `${field.replace(/_/g, ' ')} Obligatoire`
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[field];
                return newErrors;
            });
        }
    };

    const handleQuillChange = (value, field) => {
        setData({
            ...data,
            [field]: value
        });

        if (Services.isFieldRequired(location.pathname, field) && !value) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: `${field.replace(/_/g, ' ')} Obligatoire`
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[field];
                return newErrors;
            });
        }
    };

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    const renderField = (field) => {
        const key = field.name;
        const fieldType = field.type;
        const fieldOptions = field.options || [];
        const required = field.mandatory;
        const placeholder = field.placeholder || '';
        const value = data[key];

        if (key === "id") {
            return null; // Ne pas rendre le champ id
        }

        const columnClass = fieldType === 'textarea' ? 'col-12' : 'col-md-6 col-sm-12';

        switch (fieldType) {
            case 'string':
                return (
                    <div className={columnClass} key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <input
                            type="text"
                            id={key}
                            name={key}
                            className='form-control'
                            value={value?.valeur || value || ''}
                            onChange={handleInputChange}
                            placeholder={placeholder}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            case 'currency':
                return (
                    <div className={columnClass} key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <input
                            type="number"
                            id={key}
                            name={key}
                            className='form-control'
                            value={value || ''}
                            onChange={handleInputChange}
                            placeholder={placeholder}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            case 'double':
                return (
                    <div className={columnClass} key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <input
                            type="number"
                            id={key}
                            name={key}
                            className='form-control'
                            value={value || ''}
                            onChange={handleInputChange}
                            placeholder={placeholder}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            case 'picklist':
                return (
                    <div className={columnClass} key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <Select
                            id={key}
                            name={key}
                            value={{ value, label: value }}
                            options={fieldOptions.map(option => ({ value: option, label: option }))}
                            placeholder="Sélectionner..."
                            onChange={(selectedOption) => handleSelectChange(selectedOption, key)}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            case 'date':
                return (
                    <div className={columnClass} key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <DatePicker
                            id={key}
                            name={key}
                            className="form-control w-100"
                            selected={isValidDate(value) ? new Date(value) : null}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => handleDateChange(date, key)}
                            placeholderText={placeholder}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            case 'text':
                return (
                    <div className="col-12" key={key}>
                        <label htmlFor={key}>{field.name.replace(/_/g, ' ')}</label>
                        <ReactQuill
                            id={key}
                            name={key}
                            value={value || ''}
                            onChange={(value) => handleQuillChange(value, key)}
                            placeholder={placeholder}
                        />
                        {required && errors[key] && <div className="text-danger">{errors[key]}</div>}
                    </div>
                );
            default:
                return null;
        }
    };

    const formContent = () => {
        let fields = Services.getDetailViewFields(location.pathname);

        // Trier les champs pour que les 'textarea' soient toujours rendus en dernier
        fields = fields.sort((a, b) => (a.type === 'text' ? 1 : -1));

        const rows = [];

        for (let i = 0; i < fields.length; i += 2) {
            const firstField = fields[i];
            const secondField = fields[i + 1];

            rows.push(
                <div className="row mt-20" key={i}>
                    {renderField(firstField)}
                    {secondField ? renderField(secondField) : null}
                </div>
            );
        }

        return rows;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const moduleName = Services.getModuleNameByPathname(location.pathname);
        const payload = {
            setup: true,
            moduleName,
            object: data
        };

        try {
            const response = await axios.post('https://ctci.estair.travel/estair_300/public/api/user/module/save', payload);
            console.log('Réponse de l\'API:', response.data);
            
            navigate(-1); // Redirection après le succès de l'envoi
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données:', error);
            
        } finally {
            setIsLoading(false);
        }
    };

    const headerTitle = Services.getHeaderTitle(location.pathname);
    const titleField = Services.detailTitle(location.pathname);
    const detailTitle = data[titleField];
    const handleRetour = () => {
        navigate(-1);
    };

    return (
        <Layout>
            <div className="min-vh-100 bg-color container-al rapport" id="rapport">
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="bread-crumb">
                            <span className="bread-item">Liste des {headerTitle} </span>
                            <img src={chevron} alt="chevron"></img>
                            <span className="bread-item">Modifier: {detailTitle}</span>
                        </div>
                        <div className="retour pt-26 mb-20">
                            <a href='javascript:void(0);' onClick={handleRetour} className="retour-link">
                                 <span className="showToMobile"><i className="fa fa-close" aria-hidden="true"></i></span>
                <span className="hideToMobile"><i className="fa fa-arrow-left" aria-hidden="true"></i>Retour</span>
              </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 d-none d-md-block"><h1>{detailTitle}</h1></div>
                        <hr></hr>
                    </div>
                </div>
                <div className="container mt-42 bg-primary-al">
                    <form onSubmit={handleSubmit}>{formContent()}
                        <div>
                            <a href='javascript:void(0);' onClick={handleRetour} className="cta mt-20">Annuler</a>
                            <button type="submit" disabled={isLoading} className='btn-primary-al float-right mt-20'>{isLoading ? 'Mise à jour en cours...' : 'Modifier'}</button>
                            <br /><br />
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default UpdateComponent;