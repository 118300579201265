import React from "react";

const CreateCTAButton = ({ permissions, cta, toggleModal, createUrl }) => {
    if (!permissions || !cta) return null;
   
    return permissions.create && (
        cta.type === "pop-up" ? (
            <button id={cta.type} className="btn-secondary-icon" onClick={toggleModal}>
                <i className="fa fa-plus" aria-hidden="true"></i>
            </button>
        ) : (
            <a id={cta.type} style={{paddingRight: '20px'}} className="btn-secondary-icon mr-3" href={createUrl}>
                <i className="fa fa-plus" aria-hidden="true"></i>
            </a>
        )
    );
};

export default CreateCTAButton;