import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import "../assets/css/sidebar.css";
import logoCeteris from "../assets/icons/Ceteris.jpg";
import ModalChangePassword from "../components/changePassword";

function SideBar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let menus = JSON.parse(localStorage.getItem("__menu"));
    setMenuItems(menus);

    $(".sidebar ul li").on("click", function () {
      $(".sidebar ul li.active").removeClass("active");
      $(this).addClass("active");
    });

    $(".open-btn").on("click", function () {
      $(".sidebar").addClass("active");
    });

    $(".close-btn").on("click", function () {
      $(".sidebar").removeClass("active");
    });

    return () => {
      $(".sidebar ul li").off("click");
      $(".open-btn").off("click");
      $(".close-btn").off("click");
    };
  }, []);

  const logOut = () => {
    let timeout = localStorage.getItem("__timeout");
    clearTimeout(timeout);
    localStorage.removeItem("initLoad");
    localStorage.clear();
    // localStorage.removeItem("token");
    // localStorage.removeItem("__id");
    // localStorage.removeItem("__expired_myToken");
    // localStorage.removeItem("nom");
    // localStorage.removeItem("prenom");
    // localStorage.removeItem("compte");
    // localStorage.removeItem("userid");
    // localStorage.removeItem("__profil");
    // localStorage.removeItem("__role");
    navigate("/login");
  };

  const handleModal = () => {
    setIsOpen(true);
  };

  const updateIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebarOnMobile = () => {
    if (window.innerWidth < 768) {
      $(".sidebar").removeClass("active");
    }
  };

  return (
    <div className="sidebar pt-5" id="side_nav">
      {isOpen && (
        <ModalChangePassword isOpen={isOpen} updateIsOpen={updateIsOpen} />
      )}
      <div>
        <div className="header-box px-2 pt-3 pb-4 d-flex justify-content-between">
          <h1
            className="fs-4"
            style={{ padding: 10, borderRadius: 10, backgroundColor: "#fff" }}
          >
            <span className="text-white rounded d-none d-md-block">
              <img style={{ width: 96 }} src={logoCeteris} alt="Logo" />
            </span>
          </h1>
          <button className="btn d-md-none d-block close-btn px-1 py-0">
            <i className="fa fa-close" />
          </button>
        </div>
        <ul className="list-unstyled px-2">
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive
                  ? "text-decoration-none px-3 py-2 d-block active"
                  : "text-decoration-none px-3 py-2 d-block"
              }
              onClick={closeSidebarOnMobile}
            >
              <i className="fa-solid fa-house-chimney"></i> Dashboard
            </NavLink>
          </li>
          {menuItems.map((item) => (
            <li key={item.id}>
              <NavLink
                to={item.route}
                className={({ isActive }) =>
                  isActive
                    ? "text-decoration-none px-3 py-2 d-block active"
                    : "text-decoration-none px-3 py-2 d-block"
                }
                onClick={closeSidebarOnMobile}
              >
                <div className="d-flex">
                  <div style={{ width: '20px' }}>
                    <i className={item.icon}></i>
                  </div>
                  <div>{item.name}</div>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
        <hr className="h-color mx-2" />
      </div>
      <ul className="list-unstyled px-2">
        <li>
          <a href="#" className="text-decoration-none px-3 py-2 d-block">
            <i className="fa fa-user-circle-o" /> {localStorage.getItem("nom")} {localStorage.getItem("prenom")}
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleModal();
              closeSidebarOnMobile();
            }}
            className="text-decoration-none px-3 py-2 d-block"
          >


            <div className="d-flex">
              <div style={{ width: '20px' }}>
                <i className="fa-solid fa-key" />
              </div>
              <div>Modifier le mot de passe</div>
            </div>
          </a>
        </li>
        <li>
          <a
            type="button"
            onClick={() => {
              logOut();
              closeSidebarOnMobile();
            }}
            className="text-decoration-none px-3 py-2 d-block"
          >
            
            <div className="d-flex">
              <div style={{ width: '20px' }}>
              <i className="fa fa-power-off" /> 
              </div>
              <div>Déconnexion</div>
            </div>
          </a>
        </li>
        <li className="text-decoration-none px-3 py-2">
          <span className="text-white rounded d-block d-md-none">
            <img style={{ width: 96 }} src={logoCeteris} alt="Logo" />
          </span>
        </li>
      </ul>
    </div>
  );
}

export default SideBar;
