import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CreateCTAButton from "../components/CreateCTAButton";
import Services from "../services/global.service";
import PaiementModal from "../components/paiement";
import ResultModal from "../components/resultMoodal";
import EditButton from "./EditCtaButton";

const MobSideBar = () => {
  const location = useLocation();
  const [permissions, setPermissions] = useState({});
  const [cta, setCta] = useState({});
  const [headerTitle, setHeaderTitle] = useState("");
  const [createUrl, setCreateUrl] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [result, setResult] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resultParam = queryParams.get("result");
    if (resultParam === "success" || resultParam === "faild") {
      setResult(resultParam);
      setShowResultModal(true);
    }
  }, [location.search]);

  const fetchModuleData = async () => {
    let menus = JSON.parse(localStorage.getItem("__menu"));
    if (menus != null && menus.length != 0) {
      const currentModule = menus.find(
        (item) => item.route === location.pathname
      );
      if (currentModule) {
        setPermissions(currentModule.permissions);
        setCta(currentModule.cta);
      } else {
        // console.error("Module non trouvé dans menu.json");
      }
    }
    const header = Services.getHeaderTitle(location.pathname);
    const url = Services.getCreateUrl(location.pathname);
    const update = Services.getUpdateUrl(location.pathname, id);
    const download = `/api/documents/download/${id}`;

    setHeaderTitle(header);
    setCreateUrl(url);
    setUpdateUrl(update);
    setDownloadUrl(download);
  };

  useEffect(() => {
    fetchModuleData();
  }, [location.pathname]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderTitle = () => {
    switch (location.pathname) {
      case "/dashboard":
        return (
          <h1 className="animate__animated animate__zoomIn animate__delay-0.5s">
            Dashboard
          </h1>
        );
      default:
        return (
          <h1 className="animate__animated animate__zoomIn animate__delay-0.5s">
            {headerTitle}s
          </h1>
        );
    }
  };
  const isDetailView = location.pathname.includes("/detail");
  const isEditView = location.pathname.includes("/update");
  const isListView = !isDetailView && !isEditView;

  return (
    <nav className="navbar fixed-top navbar-expand-md navbar-light bg-light mobVersion">
      <div className="container-fluid">
        <div className="d-flex justify-content-between d-md-none d-block w-100 align-items-center">
          <button className="btn px-1 py-0 open-btn me-2"><i className="fa fa-bars" /></button>
          <div className="col-md-12 homeTitle">
            {renderTitle()}
          </div>
          {isListView && (
            <CreateCTAButton
              permissions={permissions}
              cta={cta}
              headerTitle={headerTitle}
              toggleModal={toggleModal}
              createUrl={createUrl}
            />
          )}
          {isDetailView && (
            <EditButton
              permissions={permissions}
              downloadUrl={downloadUrl}
              updateUrl={updateUrl}
            />
          )}
        </div>
        {/* Modal */}
        <PaiementModal isOpen={isModalOpen} updateIsOpen={toggleModal} />
        <ResultModal
          show={showResultModal}
          handleClose={() => setShowResultModal(false)}
          result={result}
        />
      </div>
    </nav>
  );
};

export default MobSideBar;
